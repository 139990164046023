/* c8 ignore start */
import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { HydratedRouter } from 'react-router/dom'
import { sentryService } from './lib/sentry'

// Only initialize Sentry if it's enabled
if (window.__ENV__.useSentry) {
  sentryService.initialize(window.__ENV__.runEnv)
}
const hydrate = () => {
  React.startTransition(() => {
    ReactDOM.hydrateRoot(
      document,
      <React.StrictMode>
        <HydratedRouter />
      </React.StrictMode>,
    )
  })
}
if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
/* c8 ignore end */
